
import { OrderTypes } from "@/store/modules/order/order.types";
import { Component, Ref, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const order = namespace("Order");

@Component({
  components: {
    OrderList: () => import("@/components/order/OrderList.vue"),
  },
})
export default class OrdersView extends Vue {
  @order.Mutation(OrderTypes.SET_ADD_ORDER_DIALOG)
  public setAddOrder!: (addOrder: boolean) => void;

  @order.Action(OrderTypes.LOAD_ORDERS)
  public loadOrders!: () => void;

  @order.State("loadingOrder")
  public loadingOrders!: boolean;

  public pageSizes = [10, 15, 20];

  public pageSize = 15;

  public query = "";

  @Ref("orderList") public orderListRef!: { queryOrders(): void };

  refresh(): void {
    this.orderListRef.queryOrders();
  }

  openAddOrderDialog(): void {
    this.setAddOrder(true);
  }
}
